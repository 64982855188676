import React, { useCallback, useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { H5, P } from '@styles/Typography';

import { Wrapper, Section } from '@components/Layout/styles';

import { SearchIcon } from '@components/Icons';
import SEO from '@components/seo';
import Accordion from '@components/Accordion';
import Breadcrumb from '@components/Breadcrumb';
import CTA from '@components/CTAS/Appointment';

import InnerBanner from '@components/InnerBanner';

import Link from '@components/Link';
import TextField from '@components/TextField';
import Sidebar from '@components/Sidebars/Main';

import * as S from './_styles';

const CondicoesClinicasPage = () => {
	const [conditions, setConditions] = useState([]);
	const [term, setTerm] = useState('');

	const { conditionsList, meta, pageData } = useStaticQuery(graphql`
		{
			meta: allCosmicjsMeta(
				filter: { slug: { eq: "meta-clinic-conditions" } }
			) {
				nodes {
					metadata {
						title
						description
						cover {
							imgix_url
						}
					}
				}
			}

			pageData: allCosmicjsPages(
				filter: { slug: { eq: "clinic-conditions" } }
			) {
				nodes {
					metadata {
						description
						title
						banner {
							url
							imgix_url
						}
					}
				}
			}

			conditionsList: allCosmicjsClinicConditions(
				sort: { order: ASC, fields: slug }
			) {
				nodes {
					metadata {
						summary
					}
					slug
					title
				}
			}
		}
	`);

	const {
		metadata: { title: pageTitle, description: pageDescription, banner },
	} = pageData.nodes[0];

	const {
		metadata: { title: metaTitle, description: metaDescription, cover },
	} = meta.nodes[0];

	const normalize = str =>
		str
			.normalize('NFD')
			.replace(/[^a-zA-Zs]/g, '')
			.toLowerCase();

	const filterCondition = useCallback(() => {
		const normalizedTerm = normalize(term);
		setConditions(
			conditionsList.nodes.filter(condition =>
				normalize(condition.title).includes(normalizedTerm)
			)
		);
	}, [conditionsList, term]);

	const filterMatches = useCallback(
		letters => {
			const regex = new RegExp(`^[${letters}]`, 'i');

			setConditions(
				conditionsList.nodes.filter(item => regex.test(item.title))
			);
		},
		[setConditions, conditionsList]
	);

	useEffect(() => {
		filterCondition();
	}, [filterCondition]);

	useEffect(() => {
		setConditions(conditionsList.nodes);
	}, [setConditions, conditionsList]);

	return (
		<>
			<SEO
				title={metaTitle || 'Condições clínicas'}
				description={metaDescription}
				cover={cover.imgix_url}
			/>

			<InnerBanner
				title={pageTitle}
				description={pageDescription}
				bg={banner.imgix_url}
			/>

			<Wrapper>
				<Breadcrumb
					crumbs={[{ title: 'Condições clínicas', slug: 'condicoes-clinicas' }]}
				/>
			</Wrapper>

			<Section>
				<S.Wrapper>
					<S.MainContent>
						<S.SearchBox>
							<S.InputSearch>
								<H5>Procure por uma condição clínica</H5>
								<TextField
									onChange={e => setTerm(e.target.value)}
									name="clinic-conditions"
									placeholder="Digite aqui uma condição clínica"
									prefix={<SearchIcon />}
									value={term}
								/>
							</S.InputSearch>

							<S.SearchByLetters
								list={conditionsList.nodes}
								handleFilter={filterMatches}
							/>
						</S.SearchBox>

						{conditions.map(({ title, slug, metadata: { summary } }) => (
							<Accordion title={title} id={slug} type="unique" key={slug}>
								<P
									dangerouslySetInnerHTML={{
										__html:
											summary.length > 200
												? `${summary.substring(0, 200)}...`
												: summary,
									}}
								/>
								<Link
									to={slug}
									withIcon
									label={`Continuar lendo sobre ${title}`}
								/>
							</Accordion>
						))}

						<CTA />
					</S.MainContent>

					<Sidebar />
				</S.Wrapper>
			</Section>
		</>
	);
};

export default CondicoesClinicasPage;
