import styled from 'styled-components';
import { media, spacing } from '@styles/Theme';
import FilterByLetters from '@components/FilterByLetters';
import { Wrapper as LWrapper } from '@components/Layout/styles';

export const MainContent = styled.div`
	@media (${media.desktop}) {
		flex: 1;
		margin-right: ${spacing(4)};
	}
`;

export const Wrapper = styled(LWrapper)`
	@media (${media.desktop}) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
`;

export const SearchBox = styled.div`
	margin-bottom: ${spacing(2)};

	@media (${media.tablet}) {
		display: flex;
		align-items: flex-end;
		margin-bottom: ${spacing(5)};
	}
`;

export const InputSearch = styled.div`
	width: 100%;
	margin-bottom: ${spacing(5)};

	@media (${media.tablet}) {
		margin-right: ${spacing(2)};
		margin-bottom: 0;
	}
`;

export const SearchByLetters = styled(FilterByLetters)`
	margin: 0 auto;
`;
